import { Inject, Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { AsyncSubject, Observable, throwError } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { DataPathUtils } from '../utils/dataPath.utils';
import { catchError, flatMap, map } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class RequestsService {
  private errorMessageDataPaths: string[] = [];
  private unauthorizedRedirectUrl: string;
  private baseUrl: string;
  private ssoUrl: string;
  private initialized$ = new AsyncSubject();

  constructor(public http: Http,
              @Inject('DataPathUtils') private readonly dataPathUtils: DataPathUtils,
              @Inject('ConfigurationService') configurationService: ConfigurationService,
              protected keycloakAngular: KeycloakService) {
    configurationService.getConfiguration().subscribe(configuration => {
      if (configuration.errorMessageDataPath) {
        if (Array.isArray(configuration.errorMessageDataPath)) {
          this.errorMessageDataPaths = configuration.errorMessageDataPath
        } else {
          this.errorMessageDataPaths = [configuration.errorMessageDataPath];
        }
      }
      this.baseUrl = this.getBaseUrl() || "";
      this.ssoUrl = this.getSSOUrl() || "";
      this.unauthorizedRedirectUrl = configuration.unauthorizedRedirectUrl;

      this.initialized$.next(undefined);
      this.initialized$.complete();
    });
  }

  public getBaseUrl() {
    const env = window.location.hostname;
    const prdEnv = 'dashboard.azship.com.br'
    const stgEnv = 'dashboard.staging.azship.com.br'
    const devEnv = 'dashboard.dev.azship.com.br'
    const PRD_API = 'https://api.azship.com.br/api/azshipservice'
    const STG_API = 'https://api.staging.azship.com.br/api/azshipservice'
    const DEV_API = 'https://api.dev.azship.com.br/api/azshipservice'

    if (env === prdEnv) {
      return PRD_API
    } else if (env === stgEnv) {
      return STG_API
    } else {
      return DEV_API
    }
    
  }

  public getSSOUrl() {
    const env = window.location.hostname;
    const prdEnv = 'dashboard.azship.com.br'
    const stgEnv = 'dashboard.staging.azship.com.br'
    const devEnv = 'dashboard.dev.azship.com.br'
    const PRD_SSO = 'https://sso.azship.com.br/api/azshipservice'
    const STG_SSO = 'https://sso.staging.azship.com.br/api/azshipservice'
    const DEV_SSO = 'https://sso.dev.azship.com.br/api/azshipservice'

    if (env === prdEnv) {
      return PRD_SSO
    } else if (env === stgEnv) {
      return STG_SSO
    } else {
      return DEV_SSO
    }
    
  }

  public get(url, headers = null, queryParams = null) {
    if (url === 'logout') {
      this.keycloakAngular.getKeycloakInstance().logout();
    } else {
      return this.executeRequestAndHandleErrors(() =>
        this.http.get(this.buildUrl(this.baseUrl + url, queryParams), { headers: this.buildHeaders(headers) }));
    }
  }

  public post(url, data, headers = null) {
    return this.executeRequestAndHandleErrors(() =>
    this.http.post(this.baseUrl + url, data, {headers: this.buildHeaders(headers)}));
  }

  public put(url, data, headers = null) {
    return this.executeRequestAndHandleErrors(() =>
      this.http.put(this.baseUrl + url, data, { headers: this.buildHeaders(headers) }));
  }

  public delete(url, headers = null) {
    return this.executeRequestAndHandleErrors(() =>
      this.http.delete(this.baseUrl + url, { headers: this.buildHeaders(headers) }));
  }

  public patch(url, data, headers = null) {
    return this.executeRequestAndHandleErrors(() =>
      this.http.patch(this.baseUrl + url, data, { headers: this.buildHeaders(headers) }));
  }

  private executeRequestAndHandleErrors(invokeRequest: () => Observable<Response>): Observable<{}> {
    return this.initialized$.pipe(flatMap(() => {
      return invokeRequest().pipe(
        map(this.extractData),
        catchError(error => this.handleError(error))
      );
    }));
  }

  private buildLogoutHeaders() {
    let headers = new Headers();
    if (this.keycloakAngular.getKeycloakInstance().token) {
      headers.append("Authorization","Bearer "+this.keycloakAngular.getKeycloakInstance().token);
      headers.append("Content-Type", "application/x-www-form-urlencoded");
    }
    return headers;
  }


  private buildHeaders(heads) {
    let headers = new Headers();

    if (heads) {
      for (const head in heads) {
        if (heads.hasOwnProperty(head)) {
          headers.append(head, heads[head]);
        }
      }
    }
    if (this.keycloakAngular.getKeycloakInstance().token) {
      headers.append("Authorization","Bearer "+this.keycloakAngular.getKeycloakInstance().token);
      headers.append("AZS-User", "admin");
    }

    return headers;
  }

  private extractData(res: Response) {
    try {
      let body = res.json();
      return body || { };
    } catch (e) {
      return res;
    }
  }

  private buildUrl(url, queryParams) {
    if (!queryParams || !queryParams.length) {
      return url;
    }

    let outputUrl = url;
    const params = [];

    for (let param of queryParams) {
      if (param.name) {
        let urlParamName = `:${param.name}`;
        if (outputUrl.indexOf(urlParamName) >= 0){
          outputUrl = outputUrl.replace(urlParamName, param.value);
        } else if (!param.urlReplaceOnly) {
          params.push(`${param.name}=${param.value || ''}`);
        }
      }
    }

    if (params.length) {
      const firstSeparator = url.indexOf('?') >= 0 ? '&' : '?';
      return outputUrl + firstSeparator + params.join('&');
    }
    return outputUrl;
  }

  private buildLogoutUrl(url) {
    return url + '/auth/realms/azship/protocol/openid-connect/logout?client_id=' +this.keycloakAngular.getKeycloakInstance().clientId;
  }


  private handleError(error: Response | any) {
    if (error instanceof Response && error.status === 401 && this.unauthorizedRedirectUrl) {
      const loginUrl = this.buildUrl(this.unauthorizedRedirectUrl, [
        {name: 'returnUrl', value: encodeURIComponent(document.location.href), urlReplaceOnly: true}
      ]);
      document.location.href = loginUrl;
      return;
    }

    const errMsg = this.getErrorMessage(error);
    console.error(errMsg, error);
    return throwError(errMsg);
  }

  private getErrorMessage(error: Response | any): string {
    if (error instanceof Response) {
      try {
        const body = error.json();
        for (const path of this.errorMessageDataPaths) {
          const dataAtPath = this.dataPathUtils.extractDataFromResponse(body, path);
          if (dataAtPath) {
            return dataAtPath;
          }
        }
      } catch {}
      return `${error.status} - ${error.statusText || ''} ${error}`;
    }
    return error.message ? error.message : error.toString();
  }
}
