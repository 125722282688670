// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import { KeycloakConfig } from 'keycloak-angular';


const env = window.location.hostname;
const prdEnv = 'dashboard.azship.com.br'
const stgEnv = 'dashboard.staging.azship.com.br'
const devEnv = 'dashboard.dev.azship.com.br'
const prdSSOAPI = 'https://sso.azship.com.br/auth'
const stgSSOAPI = 'https://sso.staging.azship.com.br/auth'
const devSSOAPI = 'https://sso.dev.azship.com.br/auth'

const getSSOEndpoint = () => {
  if (env === prdEnv) {
    return prdSSOAPI
  } else if (env === stgEnv) {
    return stgSSOAPI
  } else {
    return devSSOAPI
  }
}


// Add here your keycloak setup infos
let keycloakConfig: KeycloakConfig = {
  url: getSSOEndpoint(),
  realm: 'azship',
  clientId: 'dashboard-cli'
};

export const environment = {
  production: false,
  keycloak: keycloakConfig,
  logApiData: true
};
